/**
 * refactored from https://github.com/moezx/cdn/blob/master/js/other/404.js commit 8934ab1
 * @date 2021.10.29
 */
// This is animation
import Lottie from 'lottie-web/build/player/lottie_svg';
const data = {
    "v": "4.5.9",
    "fr": 24,
    "ip": 0,
    "op": 81,
    "w": 1000,
    "h": 1000,
    "ddd": 0,
    "assets": [{
            "id": "comp_8",
            "layers": [{
                    "ddd": 0,
                    "ind": 0,
                    "ty": 4,
                    "nm": "paw L Outlines",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 1,
                            "k": [{
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 0,
                                    "s": [8.7],
                                    "e": [-3.3]
                                }, {
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 20,
                                    "s": [-3.3],
                                    "e": [8.7]
                                }, {
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 40,
                                    "s": [8.7],
                                    "e": [-3.3]
                                }, {
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 60,
                                    "s": [-3.3],
                                    "e": [8.7]
                                }, {
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 80,
                                    "s": [8.7],
                                    "e": [-3.3]
                                }, {
                                    "t": 100
                                }]
                        },
                        "p": {
                            "a": 0,
                            "k": [540.931, 641.685, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [22.555, 0.318, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-1.348, -0.108],
                                                [-0.452, 0.265],
                                                [0.82, 1.408],
                                                [0.027, -0.343]
                                            ],
                                            "o": [
                                                [0.61, 0.049],
                                                [-1.637, -0.478],
                                                [-0.149, 0.251],
                                                [-0.108, 1.348]
                                            ],
                                            "v": [
                                                [0.377, 1.58],
                                                [2.003, 1.242],
                                                [-1.622, -1.63],
                                                [-1.895, -0.739]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.918, 0.804, 0.71, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [17.937, 57.565],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 1",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-1.638, -0.478],
                                                [-0.059, 0.738],
                                                [1.349, 0.108],
                                                [0.436, -0.736]
                                            ],
                                            "o": [
                                                [0.546, -0.321],
                                                [0.108, -1.348],
                                                [-1.005, -0.08],
                                                [0.821, 1.408]
                                            ],
                                            "v": [
                                                [1.267, 1.972],
                                                [2.251, 0.382],
                                                [-0.024, -1.893],
                                                [-2.359, -0.898]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [18.673, 56.834],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 2",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0.086, -1.077],
                                                [-1.077, -0.086],
                                                [-0.25, 0.452],
                                                [0.142, 1.151],
                                                [0.129, 0.011]
                                            ],
                                            "o": [
                                                [-0.086, 1.077],
                                                [0.635, 0.051],
                                                [-0.28, -0.953],
                                                [-0.108, -0.033],
                                                [-1.077, -0.086]
                                            ],
                                            "v": [
                                                [-1.501, -0.138],
                                                [0.292, 1.967],
                                                [1.588, 1.288],
                                                [0.956, -1.865],
                                                [0.604, -1.932]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.918, 0.804, 0.71, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [13.774, 52.364],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 3",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-0.035, 0.442],
                                                [0.789, 0.24],
                                                [-0.28, -0.952]
                                            ],
                                            "o": [
                                                [0.076, -0.948],
                                                [0.142, 1.152],
                                                [0.175, -0.315]
                                            ],
                                            "v": [
                                                [0.433, 0.422],
                                                [-0.509, -1.577],
                                                [0.122, 1.577]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [15.239, 52.075],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 4",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0.047, -0.582],
                                                [-0.675, -0.065],
                                                [0.022, 0.733]
                                            ],
                                            "o": [
                                                [-0.054, 0.678],
                                                [-0.048, -0.684],
                                                [-0.545, 0.016]
                                            ],
                                            "v": [
                                                [-0.534, -0.27],
                                                [0.588, 1.064],
                                                [0.485, -1.064]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.918, 0.804, 0.71, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [13.967, 47.57],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 5",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-0.007, -0.001],
                                                [-0.055, 0.686],
                                                [0.685, 0.055],
                                                [0.096, -0.003],
                                                [-0.048, -0.684]
                                            ],
                                            "o": [
                                                [0.685, 0.055],
                                                [0.055, -0.685],
                                                [-0.103, -0.008],
                                                [0.022, 0.733],
                                                [0.007, 0.001]
                                            ],
                                            "v": [
                                                [-0.636, 1.046],
                                                [0.703, -0.096],
                                                [-0.466, -1.093],
                                                [-0.758, -1.089],
                                                [-0.656, 1.04]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [15.211, 47.595],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 6",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0.067, -0.837],
                                                [-0.837, -0.067],
                                                [-0.067, 0.837],
                                                [0.837, 0.066]
                                            ],
                                            "o": [
                                                [-0.067, 0.837],
                                                [0.837, 0.067],
                                                [0.066, -0.837],
                                                [-0.837, -0.067]
                                            ],
                                            "v": [
                                                [-1.515, -0.272],
                                                [-0.121, 1.365],
                                                [1.517, -0.03],
                                                [0.098, -1.365]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [24.403, 56.504],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 7",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [1.174, -2.496],
                                                [-2.496, -1.174],
                                                [-1.174, 2.497],
                                                [2.496, 1.174]
                                            ],
                                            "o": [
                                                [-1.174, 2.496],
                                                [2.496, 1.175],
                                                [1.175, -2.496],
                                                [-2.497, -1.174]
                                            ],
                                            "v": [
                                                [-4.52, -2.663],
                                                [-2.126, 3.984],
                                                [4.52, 1.589],
                                                [1.066, -3.292]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [21.96, 49.281],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 8",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [3.482, 0],
                                                [0.245, 0.013],
                                                [-0.96, 12.398],
                                                [2.505, 9.483],
                                                [-1.068, 0.282],
                                                [-0.283, -1.068],
                                                [0.63, -8.133],
                                                [-8.655, -0.806],
                                                [-2, 2.027],
                                                [0.031, 0.446],
                                                [-1.103, 0.074],
                                                [-0.075, -1.103],
                                                [13.258, -13.453]
                                            ],
                                            "o": [
                                                [-0.242, 0],
                                                [-13.02, -1.211],
                                                [0.638, -8.24],
                                                [-0.283, -1.068],
                                                [1.064, -0.283],
                                                [2.678, 10.138],
                                                [-1.029, 13.298],
                                                [2.54, 0.124],
                                                [12.007, -12.186],
                                                [-0.075, -1.101],
                                                [1.119, -0.085],
                                                [0.127, 1.892],
                                                [-2.626, 2.665]
                                            ],
                                            "v": [
                                                [-4.354, 32.951],
                                                [-5.085, 32.931],
                                                [-16.316, 8.615],
                                                [-17.46, -18.448],
                                                [-16.038, -20.893],
                                                [-13.593, -19.47],
                                                [-12.329, 8.924],
                                                [-4.794, 28.943],
                                                [1.999, 26.131],
                                                [11.133, -30.737],
                                                [12.993, -32.866],
                                                [15.124, -31.006],
                                                [4.848, 28.938]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [23.585, 33.201],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 9",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [2.606, 0.138],
                                                [4.943, 22.906],
                                                [2.355, -1.008],
                                                [-18.355, -1.711],
                                                [-2.716, 5.161]
                                            ],
                                            "o": [
                                                [-19.062, 4.549],
                                                [-0.547, -2.219],
                                                [6.45, 24.415],
                                                [5.258, 0.278],
                                                [-1.912, 1.445]
                                            ],
                                            "v": [
                                                [11.216, 20.74],
                                                [-2.284, -22.294],
                                                [-7.846, -25.544],
                                                [3.297, 26.274],
                                                [15.058, 18.123]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.929, 0.592, 0.263, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [15.308, 37.862],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 10",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [10.399, 1.558],
                                                [-18.355, -1.711]
                                            ],
                                            "o": [
                                                [-4.119, 7.582],
                                                [6.451, 24.415],
                                                [22.37, 1.185]
                                            ],
                                            "v": [
                                                [15.996, -32.501],
                                                [-13.474, -20.282],
                                                [-2.008, 31.316]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.741, 0.431, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [20.612, 32.819],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 11",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }]
        }, {
            "id": "comp_9",
            "layers": [{
                    "ddd": 0,
                    "ind": 0,
                    "ty": 4,
                    "nm": "paw R Outlines",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 1,
                            "k": [{
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 0,
                                    "s": [-3.3],
                                    "e": [8.7]
                                }, {
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 20,
                                    "s": [8.7],
                                    "e": [-3.3]
                                }, {
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 40,
                                    "s": [-3.3],
                                    "e": [7.6]
                                }, {
                                    "i": {
                                        "x": [0.25],
                                        "y": [1]
                                    },
                                    "o": {
                                        "x": [0.75],
                                        "y": [0]
                                    },
                                    "n": ["0p25_1_0p75_0"],
                                    "t": 60,
                                    "s": [7.6],
                                    "e": [-3.3]
                                }, {
                                    "t": 80
                                }]
                        },
                        "p": {
                            "a": 0,
                            "k": [452.731, 649.302, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [22.454, 0.527, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-1.421, 0],
                                                [-0.266, 1.069],
                                                [1.235, 0.716],
                                                [0.261, 0.17],
                                                [0, -0.235]
                                            ],
                                            "o": [
                                                [1.219, 0],
                                                [-1.454, 0.183],
                                                [-0.264, -0.143],
                                                [-0.059, 0.199],
                                                [0, 1.421]
                                            ],
                                            "v": [
                                                [0.037, 1.442],
                                                [2.537, -0.232],
                                                [-1.65, -0.962],
                                                [-2.437, -1.442],
                                                [-2.537, -0.799]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.918, 0.804, 0.71, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [32.258, 53.233],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 1",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-1.454, 0.183],
                                                [0, 0.202],
                                                [1.422, 0],
                                                [0.298, -1.004],
                                                [-0.264, -0.143]
                                            ],
                                            "o": [
                                                [0.044, -0.177],
                                                [0, -1.421],
                                                [-1.186, 0],
                                                [0.261, 0.17],
                                                [1.235, 0.716]
                                            ],
                                            "v": [
                                                [2.451, 1.287],
                                                [2.524, 0.721],
                                                [-0.05, -1.47],
                                                [-2.524, 0.077],
                                                [-1.738, 0.558]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [32.345, 51.714],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 2",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, -1.135],
                                                [1.135, 0],
                                                [0, 1.135],
                                                [-1.135, 0]
                                            ],
                                            "o": [
                                                [0, 1.135],
                                                [-1.135, 0],
                                                [0, -1.135],
                                                [1.135, 0]
                                            ],
                                            "v": [
                                                [1.792, 0],
                                                [-0.263, 2.055],
                                                [-1.792, 0],
                                                [-0.263, -2.055]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [37.411, 47.932],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 3",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, -0.723],
                                                [0.722, 0],
                                                [0, 0.723],
                                                [-0.723, 0]
                                            ],
                                            "o": [
                                                [0, 0.723],
                                                [-0.723, 0],
                                                [0, -0.723],
                                                [0.722, 0]
                                            ],
                                            "v": [
                                                [1.309, -0.181],
                                                [0, 1.127],
                                                [-1.309, -0.181],
                                                [0, -1.127]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [37.091, 42.855],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 4",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, -0.883],
                                                [0.882, 0],
                                                [0, 0.883],
                                                [-0.883, 0]
                                            ],
                                            "o": [
                                                [0, 0.883],
                                                [-0.883, 0],
                                                [0, -0.883],
                                                [0.882, 0]
                                            ],
                                            "v": [
                                                [1.598, -0.159],
                                                [0, 1.439],
                                                [-1.598, -0.159],
                                                [0, -1.439]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.918, 0.804, 0.71, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [26.149, 51.399],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 5",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-1.021, -2.714],
                                                [2.714, -1.022],
                                                [1.021, 2.713],
                                                [-2.714, 1.021]
                                            ],
                                            "o": [
                                                [1.021, 2.714],
                                                [-2.714, 1.021],
                                                [-1.021, -2.714],
                                                [2.713, -1.022]
                                            ],
                                            "v": [
                                                [4.914, -2.535],
                                                [1.849, 4.228],
                                                [-4.914, 1.164],
                                                [-0.885, -3.661]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [29.345, 44.159],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 6",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [1.261, 0],
                                                [2.22, 1.349],
                                                [0.342, 1.875],
                                                [-1.087, 0.199],
                                                [-0.199, -1.087],
                                                [-14.669, -8.914],
                                                [-2.507, 0.771],
                                                [4.063, 12.521],
                                                [-0.232, 10.58],
                                                [-1.092, -0.017],
                                                [0.024, -1.105],
                                                [-2.581, -7.951],
                                                [12.28, -4.315]
                                            ],
                                            "o": [
                                                [-2.337, 0],
                                                [-16.195, -9.842],
                                                [-0.199, -1.086],
                                                [1.085, -0.19],
                                                [0.081, 0.441],
                                                [2.432, 1.477],
                                                [8.155, -2.867],
                                                [-2.546, -7.844],
                                                [0.024, -1.105],
                                                [1.104, 0.024],
                                                [-0.217, 9.902],
                                                [3.875, 11.94],
                                                [-1.292, 0.398]
                                            ],
                                            "v": [
                                                [7.85, 30.205],
                                                [1.01, 28.176],
                                                [-23.759, -27.688],
                                                [-22.151, -30.015],
                                                [-19.824, -28.406],
                                                [3.089, 24.759],
                                                [10.428, 25.811],
                                                [12.932, 4.276],
                                                [7.449, -23.912],
                                                [9.492, -25.868],
                                                [11.449, -23.824],
                                                [16.737, 3.042],
                                                [11.678, 29.61]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [24.208, 30.641],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 7",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [2.137, 9.915],
                                                [3.6, -0.353],
                                                [-21.415, 6.575],
                                                [1.791, 4.708],
                                                [3.824, 2.217]
                                            ],
                                            "o": [
                                                [-1.469, -6.656],
                                                [0, 0],
                                                [3.033, -0.611],
                                                [0.309, 3.818],
                                                [-10.817, -5.863]
                                            ],
                                            "v": [
                                                [-12.42, -25.052],
                                                [-20.037, -31.991],
                                                [12.986, 25.77],
                                                [18.247, 15.366],
                                                [8.372, 19.677]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.929, 0.592, 0.263, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [22.237, 32.595],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 8",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [-0.557, 25.455],
                                                [0, 0],
                                                [-21.414, 6.576]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [17.467, -6.137]
                                            ],
                                            "v": [
                                                [6.104, -26.995],
                                                [-25.137, -31.173],
                                                [7.669, 24.596]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.741, 0.431, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [27.553, 33.767],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 9",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }]
        }, {
            "id": "comp_10",
            "layers": [{
                    "ddd": 0,
                    "ind": 2,
                    "ty": 0,
                    "nm": "Other 02",
                    "td": 1,
                    "refId": "comp_11",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [478.375, 687.125, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [960, 540, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [2, 2, 100]
                        }
                    },
                    "ao": 0,
                    "w": 1920,
                    "h": 1080,
                    "ip": 19,
                    "op": 51,
                    "st": 19,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 0,
                    "nm": "Eye pre-comp",
                    "tt": 2,
                    "refId": "comp_12",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "w": 1000,
                    "h": 1000,
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }]
        }, {
            "id": "comp_11",
            "layers": [{
                    "ddd": 0,
                    "ind": 0,
                    "ty": 4,
                    "nm": "Shape Layer 7",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [960, 540, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, -100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 20.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 21.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 22.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.2,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 24,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 24.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 25.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 26.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 27.2,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "t": 28
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0, 0, 0, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 20.8,
                    "op": 28.8,
                    "st": 14.4,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "Shape Layer 5",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [960, 540, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, -100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 1.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 2.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 3.2,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 4.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 6.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -850],
                                                            [-1146, -850],
                                                            [-1098, -432],
                                                            [1028, -420]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "t": 7.2
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0, 0, 0, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 8,
                    "st": -6.4,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Shape Layer 6",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [960, 540, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 20.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 21.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 22.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.2,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 24,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 24.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 25.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 26.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 27.2,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "t": 28
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0, 0, 0, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 20.8,
                    "op": 28.8,
                    "st": 14.4,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Shape Layer 4",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [960, 540, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 1.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 2.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 3.2,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-912, -162]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [912, 162]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, 68],
                                                            [1040, 74]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 4.8,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-1000, -400]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [860.026, 344.01]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1088, -166],
                                                            [998, -150]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5.6,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 6.4,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1098, -400],
                                                            [1028, -388]
                                                        ],
                                                        "c": true
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [-916, -620]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [0, 0],
                                                            [0, 0],
                                                            [767.083, 519.205]
                                                        ],
                                                        "v": [
                                                            [1124, -818],
                                                            [-1146, -818],
                                                            [-1118, -532],
                                                            [1042, -534]
                                                        ],
                                                        "c": true
                                                    }]
                                            }, {
                                                "t": 7.2
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0, 0, 0, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 8,
                    "st": -6.4,
                    "bm": 0,
                    "sr": 1
                }]
        }, {
            "id": "comp_12",
            "layers": [{
                    "ddd": 0,
                    "ind": 0,
                    "ty": 4,
                    "nm": "eye R Outlines - Group 1",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [472.904, 687.033, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [5.068, 10.909, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, -1.155],
                                                [-1.155, 0],
                                                [0, 1.155],
                                                [1.155, 0]
                                            ],
                                            "o": [
                                                [0, 1.155],
                                                [1.155, 0],
                                                [0, -1.155],
                                                [-1.155, 0]
                                            ],
                                            "v": [
                                                [-2.091, 0],
                                                [0, 2.091],
                                                [2.091, 0],
                                                [0, -2.091]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [5.068, 10.909],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 1",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "eye R Outlines - Group 2",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [478.803, 687.033, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [10.968, 10.909, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, -3.258],
                                                [-3.258, 0],
                                                [0, 3.258],
                                                [3.258, 0]
                                            ],
                                            "o": [
                                                [0, 3.258],
                                                [3.258, 0],
                                                [0, -3.258],
                                                [-3.258, 0]
                                            ],
                                            "v": [
                                                [-5.899, 0],
                                                [0, 5.899],
                                                [5.899, 0],
                                                [0, -5.899]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [0.31, 0.243, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [10.968, 10.909],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 2",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "eye R Outlines - Group 3",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [478.744, 687.033, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [10.909, 10.909, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, -5.887],
                                                [-5.887, 0],
                                                [0, 5.887],
                                                [5.887, 0]
                                            ],
                                            "o": [
                                                [0, 5.887],
                                                [5.887, 0],
                                                [0, -5.887],
                                                [-5.887, 0]
                                            ],
                                            "v": [
                                                [-10.659, 0],
                                                [0, 10.659],
                                                [10.659, 0],
                                                [0, -10.659]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "fl",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "r": 1,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [10.909, 10.909],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Group 3",
                            "np": 2,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }]
        }, {
            "id": "comp_13",
            "layers": [{
                    "ddd": 0,
                    "ind": 0,
                    "ty": 4,
                    "nm": "Shape Layer 3",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-4.375, 0.063],
                                                [0, 0],
                                                [-4.188, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [4.375, -0.063],
                                                [0, 0],
                                                [4.188, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [223.531, 132.375],
                                                [224.344, 145.313],
                                                [230.219, 133.188],
                                                [233.281, 143.625],
                                                [237.906, 133.5],
                                                [240.656, 145.063]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 3",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0.75, 3.688],
                                                [0.438, 0],
                                                [-1.438, -6.625],
                                                [-0.688, 3.75]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [-0.75, -3.688],
                                                [-0.438, 0],
                                                [1.438, 6.625],
                                                [0.688, -3.75]
                                            ],
                                            "v": [
                                                [212.344, 138.438],
                                                [220.594, 135.313],
                                                [215.969, 132.875],
                                                [211.656, 142.063],
                                                [220.719, 142.813]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 2",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [207.691, 127.188],
                                                [207.629, 145]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "Shape Layer 2",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-3.688, 0.063],
                                                [0, -0.813],
                                                [7.063, 0.5]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [3.688, -0.063],
                                                [0, 0.813],
                                                [-7.063, -0.5]
                                            ],
                                            "v": [
                                                [195.5, 126],
                                                [195.5, 144.25],
                                                [200.813, 133.438],
                                                [203.75, 139.5],
                                                [196.563, 144.125]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 5",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, -5.875],
                                                [-1.5, 0],
                                                [0.188, 5.875]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 5.875],
                                                [1.5, 0],
                                                [-0.188, -5.875]
                                            ],
                                            "v": [
                                                [186.438, 132.375],
                                                [180.563, 139.188],
                                                [186.188, 145.188],
                                                [192.125, 136.875]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 4",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-4.625, 2.813]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [4.625, -2.813]
                                            ],
                                            "v": [
                                                [172, 133.625],
                                                [172.688, 144.875],
                                                [177.313, 132.688]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 3",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-4.063, -0.125],
                                                [0, 0],
                                                [4.938, 0.438],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [5.125, 0.125],
                                                [0, 0],
                                                [-4.938, -0.438],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [158.063, 151.938],
                                                [157.938, 137.563],
                                                [163.063, 133.25],
                                                [168.813, 139.75],
                                                [163.688, 144.125],
                                                [158.5, 142.375]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 2",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [-1.938, -5.375],
                                                [3.688, -0.375],
                                                [-0.063, 1.375],
                                                [-3, -0.313],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [1.938, 5.375],
                                                [-3.688, 0.375],
                                                [0.063, -1.375],
                                                [3, 0.313],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [131.563, 133.5],
                                                [140, 136.313],
                                                [137.063, 146],
                                                [132.5, 141.375],
                                                [137.063, 137.938],
                                                [140.313, 139.813]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Shape Layer 1",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0.375, 3.813],
                                                [0, 0],
                                                [-0.375, -5.75],
                                                [-4.375, 6.375]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [-0.375, -3.063],
                                                [0, 0],
                                                [0.375, 5.75],
                                                [4.375, -6.375]
                                            ],
                                            "v": [
                                                [223.75, 112.438],
                                                [231.563, 109.25],
                                                [228.063, 106.625],
                                                [222.688, 112.25],
                                                [231.688, 116.813]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "Text",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [209.188, 107],
                                                [215.063, 118.438],
                                                [219.5, 107.938]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 16",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [-1.25, -12.313],
                                                [0, 0],
                                                [0.5, 3.813],
                                                [-1.5, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0.125, 4.813],
                                                [0, 0],
                                                [-0.5, -3.813],
                                                [1.5, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [195.563, 107.375],
                                                [205.938, 115.125],
                                                [201.5, 119.875],
                                                [197.125, 115.938],
                                                [200.875, 111.75],
                                                [205.188, 113.5]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 15",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-2.813, 0.063],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [2.813, -0.063],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [184, 103.125],
                                                [184.875, 119.75],
                                                [189.438, 108.688],
                                                [192.5, 119.625]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 14",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [-0.125, 3.438],
                                                [0, 0],
                                                [-3, -8.938],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0.125, -3.438],
                                                [0, 0],
                                                [2.875, 6.563],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [160, 112.625],
                                                [167.75, 110],
                                                [164.125, 106.688],
                                                [159.813, 117.313],
                                                [167.875, 117.125]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 13",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [-2.438, -0.25],
                                                [0, 0],
                                                [-3, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [2.438, 0.25],
                                                [0, 0],
                                                [3, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [139.938, 107.313],
                                                [144.25, 118.25],
                                                [147.688, 108.063],
                                                [150.5, 118.688],
                                                [155.625, 107.5]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 12",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [3.188, -1.75]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [-3.188, 1.75]
                                            ],
                                            "v": [
                                                [232.875, 91.5],
                                                [230.313, 97.438]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 11",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-4.188, 0.375],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [4.188, -0.375],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [217.438, 80.375],
                                                [218.125, 93.625],
                                                [222.688, 82.125],
                                                [227.125, 94.625]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 10",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, -5.75],
                                                [-2.25, 0],
                                                [0, 4.438]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 5.75],
                                                [2.25, 0],
                                                [-0.063, -5.688]
                                            ],
                                            "v": [
                                                [209.188, 80.313],
                                                [203.25, 86.75],
                                                [209, 93.125],
                                                [214.25, 86.563]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 9",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [193.313, 81.5],
                                                [198.625, 81.5]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 8",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-4.25, -0.188]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [4.25, 0.188]
                                            ],
                                            "v": [
                                                [195.75, 77.875],
                                                [195.75, 88.938],
                                                [200.125, 93.563]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 7",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, -4],
                                                [-1.375, -0.813],
                                                [3.438, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 4],
                                                [1.375, 0.813],
                                                [-3.438, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [190.375, 82.563],
                                                [181.438, 84.188],
                                                [190.375, 88],
                                                [186.75, 92.5],
                                                [181.75, 89.813]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 6",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0],
                                                [-4.125, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0],
                                                [5.188, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [169.813, 80.625],
                                                [169.563, 86.938],
                                                [174.438, 92.688],
                                                [177.875, 83.063]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 5",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [1.75, 0],
                                                [0, -5.375],
                                                [-1.813, 0],
                                                [0, 4.125]
                                            ],
                                            "o": [
                                                [-1.75, 0],
                                                [0, 5.375],
                                                [1.813, 0],
                                                [0, -6]
                                            ],
                                            "v": [
                                                [160.625, 80.25],
                                                [155.188, 86.75],
                                                [160.75, 92.938],
                                                [166.188, 86.625]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 4",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [150.75, 84.438],
                                                [139.688, 84.438]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 3",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [151.688, 93.625],
                                                [151.188, 76.625]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 2",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [139, 92.375],
                                                [139, 76.625]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 2.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }]
        }, {
            "id": "comp_14",
            "layers": [{
                    "ddd": 0,
                    "ind": 0,
                    "ty": 4,
                    "nm": "Shape Layer 1",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [3, -28.75]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [-3, 28.75]
                                            ],
                                            "v": [
                                                [45.25, -297.25],
                                                [50.5, -232.75]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 6.1
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 5",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [-1.75, -3],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [1.75, 3],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [27.75, -299.75],
                                                [26.25, -253.5],
                                                [63.75, -253.5]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 6.1
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 4",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [4.188, 0],
                                                [0, -35],
                                                [-7.5, 0],
                                                [1.25, 32.25]
                                            ],
                                            "o": [
                                                [-4.188, 0],
                                                [0, 35],
                                                [7.5, 0],
                                                [-1.25, -32.25]
                                            ],
                                            "v": [
                                                [-7.75, -300.25],
                                                [-31.5, -259.75],
                                                [-9.25, -227.25],
                                                [11.75, -266.5]
                                            ],
                                            "c": true
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 6.1
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 3",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [2.75, -23.25]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [-2.75, 23.25]
                                            ],
                                            "v": [
                                                [-62.25, -298],
                                                [-57.5, -233.75]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 6.1
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 2",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [-0.25, -3.5],
                                                [0, 0]
                                            ],
                                            "o": [
                                                [0, 0],
                                                [0.25, 3.5],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [-81, -300],
                                                [-81.75, -253.25],
                                                [-43.5, -253.5]
                                            ],
                                            "c": false
                                        }
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 6.1
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }]
        }, {
            "id": "comp_15",
            "layers": [{
                    "ddd": 0,
                    "ind": 0,
                    "ty": 4,
                    "nm": "light leak",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.432, 6.772],
                                                            [59.462, 37.439]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [14.997, -18.696],
                                                            [-81, -51]
                                                        ],
                                                        "v": [
                                                            [-182.75, -42],
                                                            [-172.581, -52.731],
                                                            [-196.75, -149]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 10,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 14,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 19,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.75,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 28,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 33,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 38,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 43,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 47.499,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 52.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 57,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 61.751,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 66.501,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 71.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 76,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 81,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-158.016, -109.372],
                                                            [-194.25, -150.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "t": 85
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.82, 0.643, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 3.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "tm",
                            "s": {
                                "a": 0,
                                "k": 68.1,
                                "ix": 1
                            },
                            "e": {
                                "a": 0,
                                "k": 78.5,
                                "ix": 2
                            },
                            "o": {
                                "a": 0,
                                "k": 0,
                                "ix": 3
                            },
                            "m": 1,
                            "ix": 2,
                            "nm": "Trim Paths 1",
                            "mn": "ADBE Vector Filter - Trim"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "cap 3",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.432, 6.772],
                                                            [59.462, 37.439]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [14.997, -18.696],
                                                            [-81, -51]
                                                        ],
                                                        "v": [
                                                            [-182.75, -42],
                                                            [-172.581, -52.731],
                                                            [-196.75, -149]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 10,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 14,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 19,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.75,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 28,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 33,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 38,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 43,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 47.499,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 52.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 57,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 61.751,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 66.501,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 71.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 76,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 81,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-158.016, -109.372],
                                                            [-194.25, -150.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "t": 85
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 16.8
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "tm",
                            "s": {
                                "a": 0,
                                "k": 97,
                                "ix": 1
                            },
                            "e": {
                                "a": 0,
                                "k": 93.2,
                                "ix": 2
                            },
                            "o": {
                                "a": 0,
                                "k": 0,
                                "ix": 3
                            },
                            "m": 1,
                            "ix": 2,
                            "nm": "Trim Paths 1",
                            "mn": "ADBE Vector Filter - Trim"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "cap 2",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.432, 6.772],
                                                            [59.462, 37.439]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [14.997, -18.696],
                                                            [-81, -51]
                                                        ],
                                                        "v": [
                                                            [-182.75, -42],
                                                            [-172.581, -52.731],
                                                            [-196.75, -149]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 10,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 14,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 19,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.75,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 28,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 33,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 38,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 43,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 47.499,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 52.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 57,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 61.751,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 66.501,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 71.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 76,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 81,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-158.016, -109.372],
                                                            [-194.25, -150.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "t": 85
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.918, 0.804, 0.71, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 16.8
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "tm",
                            "s": {
                                "a": 0,
                                "k": 99.4,
                                "ix": 1
                            },
                            "e": {
                                "a": 0,
                                "k": 100,
                                "ix": 2
                            },
                            "o": {
                                "a": 0,
                                "k": 0,
                                "ix": 3
                            },
                            "m": 1,
                            "ix": 2,
                            "nm": "Trim Paths 1",
                            "mn": "ADBE Vector Filter - Trim"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 3,
                    "ty": 4,
                    "nm": "cap",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.432, 6.772],
                                                            [59.462, 37.439]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [14.997, -18.696],
                                                            [-81, -51]
                                                        ],
                                                        "v": [
                                                            [-182.75, -42],
                                                            [-172.581, -52.731],
                                                            [-196.75, -149]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 10,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 14,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 19,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.75,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 28,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 33,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 38,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 43,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 47.499,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 52.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 57,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 61.751,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 66.501,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 71.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 76,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 81,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-158.016, -109.372],
                                                            [-194.25, -150.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "t": 85
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 1, 1, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 16.8
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }, {
                            "ty": "tm",
                            "s": {
                                "a": 0,
                                "k": 92.3,
                                "ix": 1
                            },
                            "e": {
                                "a": 0,
                                "k": 100,
                                "ix": 2
                            },
                            "o": {
                                "a": 0,
                                "k": 0,
                                "ix": 3
                            },
                            "m": 1,
                            "ix": 2,
                            "nm": "Trim Paths 1",
                            "mn": "ADBE Vector Filter - Trim"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 4,
                    "ty": 4,
                    "nm": "tail",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500.25, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.432, 6.772],
                                                            [59.462, 37.439]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [14.997, -18.696],
                                                            [-81, -51]
                                                        ],
                                                        "v": [
                                                            [-182.75, -42],
                                                            [-172.581, -52.731],
                                                            [-196.75, -149]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 10,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 14,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 19,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.75,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 28,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 33,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 38,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 43,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 47.499,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 52.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 57,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 61.751,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 66.501,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 71.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 76,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 81,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-158.016, -109.372],
                                                            [-194.25, -150.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "t": 85
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [1, 0.741, 0.431, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 14
                                    },
                                    "lc": 1,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [1, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 5,
                    "ty": 4,
                    "nm": "tail 2",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.432, 6.772],
                                                            [59.462, 37.439]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [14.997, -18.696],
                                                            [-81, -51]
                                                        ],
                                                        "v": [
                                                            [-182.75, -42],
                                                            [-172.581, -52.731],
                                                            [-196.75, -149]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 10,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 14,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 19,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.75,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 28,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 33,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 38,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 43,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 47.499,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 52.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 57,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 61.751,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 66.501,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 71.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 76,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 81,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-158.016, -109.372],
                                                            [-194.25, -150.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "t": 85
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.929, 0.592, 0.263, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 16.8
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }, {
                    "ddd": 0,
                    "ind": 6,
                    "ty": 4,
                    "nm": "stroke",
                    "ks": {
                        "o": {
                            "a": 0,
                            "k": 100
                        },
                        "r": {
                            "a": 0,
                            "k": 0
                        },
                        "p": {
                            "a": 0,
                            "k": [500, 500, 0]
                        },
                        "a": {
                            "a": 0,
                            "k": [0, 0, 0]
                        },
                        "s": {
                            "a": 0,
                            "k": [100, 100, 100]
                        }
                    },
                    "ao": 0,
                    "shapes": [{
                            "ty": "gr",
                            "it": [{
                                    "ind": 0,
                                    "ty": "sh",
                                    "ks": {
                                        "a": 1,
                                        "k": [{
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 0,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.432, 6.772],
                                                            [59.462, 37.439]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [14.997, -18.696],
                                                            [-81, -51]
                                                        ],
                                                        "v": [
                                                            [-182.75, -42],
                                                            [-172.581, -52.731],
                                                            [-196.75, -149]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 5,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-10.134, 15.247],
                                                            [41.25, 28.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [22.516, -33.878],
                                                            [-78.75, -54.409]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.016, -64.497],
                                                            [-189.5, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 10,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-8.285, 12.85],
                                                            [35, 24]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [26.516, -41.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-170.766, -66.872],
                                                            [-183.75, -142]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 14,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-11.484, 20.122],
                                                            [21.625, 13.125]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [27.516, -40.878],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-173.766, -79.872],
                                                            [-170.5, -142.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 19,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-6.732, 19.872],
                                                            [15.997, 17.501]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.018, -20.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-178.018, -89.872],
                                                            [-160.997, -141.001]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 23.75,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-5.457, 18.05],
                                                            [5.25, 16.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [10.016, -33.128],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-184.516, -89.872],
                                                            [-148.25, -144]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 28,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-7.484, 26.122],
                                                            [-8.5, 2.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [9.532, -33.271],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.766, -90.122],
                                                            [-139.25, -135.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 33,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-4.984, 27.872],
                                                            [-10.75, 0.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [6.512, -36.416],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-197.516, -91.622],
                                                            [-151.75, -140.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 38,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-2.985, 25.872],
                                                            [-10.002, 3.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [3.995, -34.631],
                                                            [-82.192, -49.057]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-202.015, -91.372],
                                                            [-160.248, -141.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 43,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [-3.984, 28.372],
                                                            [-12.5, 2.5]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [4.016, -34.628],
                                                            [-7.25, -2.75]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-208.516, -98.372],
                                                            [-168.5, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 47.499,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [2.516, 25.872],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-2.385, -24.517],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-180.75, -147.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 52.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-22.75, 7]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-214.516, -99.872],
                                                            [-185, -147]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 57,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-33.75, 18.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-206.266, -100.372],
                                                            [-200.25, -150]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 61.751,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [17.224, 27.795],
                                                            [-15, 19]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-11.234, -18.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-195.516, -106.622],
                                                            [-209.75, -148.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 66.501,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [-9.75, 22.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-187.266, -108.122],
                                                            [-217.25, -153.75]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 71.25,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [26, 6]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-177.516, -115.872],
                                                            [-221.5, -151.25]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 76,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [12.7, 30.132],
                                                            [25, 7.75]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-8.484, -20.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-169.516, -116.622],
                                                            [-211.5, -154.5]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "i": {
                                                    "x": 0.833,
                                                    "y": 0.833
                                                },
                                                "o": {
                                                    "x": 0.167,
                                                    "y": 0.167
                                                },
                                                "n": "0p833_0p833_0p167_0p167",
                                                "t": 81,
                                                "s": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-159.266, -104.872],
                                                            [-196.125, -148.625]
                                                        ],
                                                        "c": false
                                                    }],
                                                "e": [{
                                                        "i": [
                                                            [0, 0],
                                                            [8.885, 37.903],
                                                            [21.5, 13.25]
                                                        ],
                                                        "o": [
                                                            [0, 0],
                                                            [-4.484, -19.128],
                                                            [-48, -26.25]
                                                        ],
                                                        "v": [
                                                            [-183.5, -42.5],
                                                            [-158.016, -109.372],
                                                            [-194.25, -150.25]
                                                        ],
                                                        "c": false
                                                    }]
                                            }, {
                                                "t": 85
                                            }]
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group"
                                }, {
                                    "ty": "st",
                                    "c": {
                                        "a": 0,
                                        "k": [0.322, 0.22, 0.357, 1]
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100
                                    },
                                    "w": {
                                        "a": 0,
                                        "k": 25.2
                                    },
                                    "lc": 2,
                                    "lj": 1,
                                    "ml": 4,
                                    "nm": "Stroke 1",
                                    "mn": "ADBE Vector Graphic - Stroke"
                                }, {
                                    "ty": "tr",
                                    "p": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 2
                                    },
                                    "a": {
                                        "a": 0,
                                        "k": [0, 0],
                                        "ix": 1
                                    },
                                    "s": {
                                        "a": 0,
                                        "k": [100, 100],
                                        "ix": 3
                                    },
                                    "r": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 6
                                    },
                                    "o": {
                                        "a": 0,
                                        "k": 100,
                                        "ix": 7
                                    },
                                    "sk": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 4
                                    },
                                    "sa": {
                                        "a": 0,
                                        "k": 0,
                                        "ix": 5
                                    },
                                    "nm": "Transform"
                                }],
                            "nm": "Shape 1",
                            "np": 3,
                            "mn": "ADBE Vector Group"
                        }],
                    "ip": 0,
                    "op": 81,
                    "st": 0,
                    "bm": 0,
                    "sr": 1
                }]
        }],
    "layers": [{
            "ddd": 0,
            "ind": 0,
            "ty": 4,
            "nm": "Shape Layer 1",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "a": {
                    "a": 0,
                    "k": [0, 0, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 1,
            "ty": 4,
            "nm": "leg R Outlines",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 1,
                    "k": [{
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": -20,
                            "s": [0],
                            "e": [8.6]
                        }, {
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 0,
                            "s": [8.6],
                            "e": [0]
                        }, {
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 20,
                            "s": [0],
                            "e": [8.6]
                        }, {
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 40,
                            "s": [8.6],
                            "e": [1]
                        }, {
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 60,
                            "s": [1],
                            "e": [8.6]
                        }, {
                            "t": 81
                        }]
                },
                "p": {
                    "a": 0,
                    "k": [463.282, 549.841, 0]
                },
                "a": {
                    "a": 0,
                    "k": [63.702, 63.684, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [{
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [4.499, 0],
                                        [0.693, 0.08],
                                        [2.217, 4.953],
                                        [-2.287, 8.436],
                                        [-0.44, 1.97],
                                        [-0.031, 2.408],
                                        [1.292, 1.411],
                                        [0.091, -0.008],
                                        [0.283, -0.525],
                                        [0.134, -1.585],
                                        [0.006, -0.043],
                                        [20.369, -1.962],
                                        [7.858, 22.179],
                                        [-1.041, 0.368],
                                        [-0.37, -1.042],
                                        [-17.283, 1.664],
                                        [-2.889, 19.157],
                                        [-1.036, 1.917],
                                        [-1.662, 0.205],
                                        [-1.114, -1.217],
                                        [0.045, -3.529],
                                        [0.789, -3.533],
                                        [0.555, -2.049],
                                        [-3.246, -7.25],
                                        [-4.777, -0.552],
                                        [-1.977, 2.598],
                                        [1.504, 6.013],
                                        [-0.928, 11.882],
                                        [-0.169, 3.997],
                                        [7.199, 2.046],
                                        [2.984, -2.315],
                                        [0, 0],
                                        [2.413, 0.519],
                                        [2.149, 3.999],
                                        [0.664, 0.998],
                                        [2.371, 2.047],
                                        [2.43, 0.924],
                                        [-0.392, 1.032],
                                        [-1.034, -0.393],
                                        [-2.973, -2.567],
                                        [-2.033, -3.052],
                                        [-0.75, -1.395],
                                        [-2.401, -0.515],
                                        [-1.741, 1.336],
                                        [0, 0],
                                        [-5.322, -1.512],
                                        [0.62, -14.656],
                                        [0.308, -3.94],
                                        [-2.931, -11.726],
                                        [4.065, -5.34]
                                    ],
                                    "o": [
                                        [-0.669, 0],
                                        [-6.206, -0.716],
                                        [-3.83, -8.553],
                                        [0.569, -2.098],
                                        [0.727, -3.257],
                                        [0.024, -1.854],
                                        [-0.334, -0.365],
                                        [-0.301, 0.037],
                                        [-0.732, 1.354],
                                        [-0.004, 0.043],
                                        [-3.16, 21.127],
                                        [-18.822, 1.812],
                                        [-0.369, -1.041],
                                        [1.04, -0.373],
                                        [7.172, 20.245],
                                        [18.401, -1.772],
                                        [0.18, -2.113],
                                        [0.871, -1.611],
                                        [1.532, -0.189],
                                        [2.179, 2.38],
                                        [-0.035, 2.691],
                                        [-0.46, 2.059],
                                        [-2.104, 7.758],
                                        [1.636, 3.652],
                                        [3.915, 0.45],
                                        [3.042, -3.997],
                                        [-3.089, -12.358],
                                        [0.304, -3.906],
                                        [0.303, -7.188],
                                        [-3.376, -0.955],
                                        [0, 0],
                                        [-2.714, 2.084],
                                        [-4.209, -0.903],
                                        [-0.703, -1.31],
                                        [-1.832, -2.75],
                                        [-2.577, -2.224],
                                        [-1.033, -0.392],
                                        [0.392, -1.032],
                                        [2.908, 1.104],
                                        [2.645, 2.283],
                                        [0.769, 1.155],
                                        [1.738, 3.235],
                                        [1.213, 0.263],
                                        [0, 0],
                                        [3.361, -2.609],
                                        [7.4, 2.103],
                                        [-0.172, 4.067],
                                        [-0.943, 12.096],
                                        [1.746, 6.981],
                                        [-2.484, 3.265]
                                    ],
                                    "v": [
                                        [44.748, 57.921],
                                        [42.701, 57.802],
                                        [28.618, 48.324],
                                        [29.441, 22.613],
                                        [30.988, 16.562],
                                        [32.098, 8.259],
                                        [30.88, 1.138],
                                        [30.217, 0.814],
                                        [29.202, 1.648],
                                        [28.133, 6.392],
                                        [28.118, 6.52],
                                        [-9.458, 43.393],
                                        [-59.317, 13.259],
                                        [-58.1, 10.706],
                                        [-55.545, 11.923],
                                        [-9.84, 39.411],
                                        [24.153, 5.99],
                                        [25.683, -0.255],
                                        [29.727, -3.156],
                                        [33.83, -1.563],
                                        [36.098, 8.31],
                                        [34.891, 17.433],
                                        [33.301, 23.66],
                                        [32.269, 46.69],
                                        [43.16, 53.828],
                                        [52.436, 50.451],
                                        [53.003, 34.327],
                                        [51.801, -2.199],
                                        [52.59, -14.141],
                                        [45.129, -35.74],
                                        [35.386, -31.758],
                                        [34.661, -31.197],
                                        [26.938, -28.837],
                                        [18.145, -38.271],
                                        [16.114, -41.816],
                                        [9.779, -49.045],
                                        [2.234, -53.79],
                                        [1.075, -56.369],
                                        [3.654, -57.528],
                                        [12.394, -52.072],
                                        [19.442, -44.033],
                                        [21.67, -40.165],
                                        [27.778, -32.748],
                                        [32.226, -34.37],
                                        [32.933, -34.918],
                                        [46.222, -39.588],
                                        [56.587, -13.971],
                                        [55.788, -1.889],
                                        [56.884, 33.357],
                                        [55.62, 52.873]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [67.469, 67.673],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 1",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [5.625, 8.162],
                                        [-0.372, 5.471],
                                        [1.907, 0.534],
                                        [-3.063, -6.842],
                                        [-5.844, 0.038],
                                        [-2.24, 2.907],
                                        [-0.001, 0.002]
                                    ],
                                    "o": [
                                        [-2.625, -3.069],
                                        [-1.931, -0.273],
                                        [-1.823, 7.048],
                                        [2.271, 5.072],
                                        [3.55, -0.054],
                                        [1.768, -2.297],
                                        [-0.001, 0]
                                    ],
                                    "v": [
                                        [-3.032, -0.102],
                                        [-5.64, -13.574],
                                        [-11.4, -14.776],
                                        [-11.456, 6.329],
                                        [2.93, 14.738],
                                        [12.102, 10.484],
                                        [14.519, 2.483]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.918, 0.804, 0.71, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [109.368, 108.852],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 2",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [2.271, 5.072],
                                        [-1.823, 7.049],
                                        [-1.931, -0.274],
                                        [11.443, 1.413],
                                        [0.002, -0.138],
                                        [0.646, -2.861],
                                        [-4.5, -9.933],
                                        [-5.95, 0.091]
                                    ],
                                    "o": [
                                        [-3.063, -6.841],
                                        [1.907, 0.534],
                                        [0.887, -13.023],
                                        [-0.002, 0.148],
                                        [-0.038, 2.938],
                                        [-2.227, 9.855],
                                        [2.315, 5.111],
                                        [-5.844, 0.038]
                                    ],
                                    "v": [
                                        [-4.791, 20.965],
                                        [-4.735, -0.14],
                                        [1.024, 1.063],
                                        [-4.493, -29.466],
                                        [-1.436, -15.32],
                                        [-2.57, -9.545],
                                        [-5.094, 20.965],
                                        [9.594, 29.375]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [102.704, 94.215],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 3",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [9.554, 26.656],
                                        [-32.696, 18.31],
                                        [-6.344, -25.735],
                                        [-18.029, 29.209]
                                    ],
                                    "o": [
                                        [-7.123, -20.101],
                                        [-39.139, 4.232],
                                        [11.644, 32.487],
                                        [-22.067, 13.465]
                                    ],
                                    "v": [
                                        [-25.282, 3.828],
                                        [-6.526, -53.063],
                                        [-36.034, 15.217],
                                        [42.462, 25.984]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [44.785, 65.355],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100.6, 100.6],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 4",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [-2.625, -3.069],
                                        [-0.001, 0],
                                        [0.743, 2.976],
                                        [0.311, 2.196],
                                        [5.756, 0.816]
                                    ],
                                    "o": [
                                        [5.625, 8.162],
                                        [0.03, -3.358],
                                        [-0.552, -2.208],
                                        [-5.813, 0.916],
                                        [-0.372, 5.471]
                                    ],
                                    "v": [
                                        [-7.3, 2.838],
                                        [10.251, 5.424],
                                        [8.777, -4.396],
                                        [7.504, -11.001],
                                        [-9.908, -10.633]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 1, 1, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [113.637, 105.911],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 5",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [1.386, 5.545],
                                        [-0.686, 16.192],
                                        [8.802, 2.501],
                                        [3.34, -2.565],
                                        [3.71, 5.571],
                                        [2.571, 2.219],
                                        [2.994, 1.138],
                                        [-9.788, -40.171],
                                        [-7.196, 48.117],
                                        [-0.897, 1.659],
                                        [-1.619, -1.768],
                                        [0.032, -2.497],
                                        [0.639, -2.862],
                                        [-4.448, -9.933],
                                        [-5.924, 7.782]
                                    ],
                                    "o": [
                                        [-4.008, -16.034],
                                        [0.311, -7.345],
                                        [-4.679, -1.328],
                                        [-8.845, 6.791],
                                        [-1.882, -2.825],
                                        [-2.41, -2.081],
                                        [-48.535, -11.764],
                                        [13.375, 37.75],
                                        [0.156, -1.853],
                                        [1, -1.85],
                                        [1.798, 1.964],
                                        [-0.038, 2.938],
                                        [-2.2, 9.855],
                                        [3.653, 8.16],
                                        [3.773, -4.956]
                                    ],
                                    "v": [
                                        [59.653, 37.831],
                                        [59.297, -10.066],
                                        [50.385, -33.674],
                                        [38.153, -28.794],
                                        [22.487, -38.935],
                                        [15.795, -46.57],
                                        [7.653, -51.669],
                                        [-52.722, 16.581],
                                        [30.849, 10.214],
                                        [32.152, 4.685],
                                        [37.064, 3.776],
                                        [38.807, 12.275],
                                        [37.648, 20.987],
                                        [35.153, 51.496],
                                        [58.737, 55.651]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 0.741, 0.431, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [62.76, 63.684],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 6",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "mouse Outlines",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [500.747, 672.596, 0]
                },
                "a": {
                    "a": 0,
                    "k": [4.625, 4.708, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [{
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 2.462],
                                        [2.416, 0],
                                        [0, -2.462],
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, -2.462],
                                        [-2.416, 0],
                                        [0, 2.462],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [4.375, 0],
                                        [0, -4.458],
                                        [-4.375, 0],
                                        [0, 4.458]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.31, 0.243, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [4.625, 4.708],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 1",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 3,
            "ty": 4,
            "nm": "nose Outlines",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [500.747, 681.836, 0]
                },
                "a": {
                    "a": 0,
                    "k": [6.388, 6.657, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [{
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [1.5, 3.938],
                                        [-1.5, 3.938],
                                        [-1.5, -3.938],
                                        [1.5, -3.938]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.31, 0.243, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [6.388, 4.188],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 1",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, -2.83],
                                        [0, 1.508],
                                        [2.529, 0]
                                    ],
                                    "o": [
                                        [0, 1.508],
                                        [0, -2.83],
                                        [-2.529, 0]
                                    ],
                                    "v": [
                                        [-6.138, 1.808],
                                        [6.138, 1.808],
                                        [0, -3.317]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.31, 0.243, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [6.388, 9.748],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 2",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 4,
            "ty": 0,
            "nm": "Paw R",
            "refId": "comp_8",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": -7.7
                },
                "p": {
                    "a": 0,
                    "k": [538.625, 649.75, 0]
                },
                "a": {
                    "a": 0,
                    "k": [537, 648, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "w": 1000,
            "h": 1000,
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 5,
            "ty": 0,
            "nm": "Paw",
            "refId": "comp_9",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [500, 503.5, 0]
                },
                "a": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "w": 1000,
            "h": 1000,
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 6,
            "ty": 0,
            "nm": "Eye",
            "refId": "comp_10",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [544, 500, 0]
                },
                "a": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "w": 1000,
            "h": 1000,
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 7,
            "ty": 0,
            "nm": "Eye",
            "refId": "comp_10",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "a": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "w": 1000,
            "h": 1000,
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 8,
            "ty": 4,
            "nm": "ball",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [517.819, 783.702, 0]
                },
                "a": {
                    "a": 0,
                    "k": [26, 26, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [{
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [11.993, 0],
                                        [0, -11.993],
                                        [-11.993, 0],
                                        [0, 11.993]
                                    ],
                                    "o": [
                                        [-11.993, 0],
                                        [0, 11.993],
                                        [11.993, 0],
                                        [0, -11.993]
                                    ],
                                    "v": [
                                        [0, -21.75],
                                        [-21.75, 0],
                                        [0, 21.75],
                                        [21.75, 0]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ind": 1,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [14.198, 0],
                                        [0, 14.198],
                                        [-14.198, 0],
                                        [0, -14.198]
                                    ],
                                    "o": [
                                        [-14.198, 0],
                                        [0, -14.198],
                                        [14.198, 0],
                                        [0, 14.198]
                                    ],
                                    "v": [
                                        [0, 25.75],
                                        [-25.75, 0],
                                        [0, -25.75],
                                        [25.75, 0]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 2",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "mm",
                            "mm": 1,
                            "nm": "Merge Paths 1",
                            "mn": "ADBE Vector Filter - Merge"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [26, 26],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 1",
                    "np": 4,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [7.183, 0.596],
                                        [-0.997, 12.007],
                                        [-1.9, 2.925],
                                        [0.677, -8.156],
                                        [-13.071, -1.085],
                                        [-2.664, 11.238]
                                    ],
                                    "o": [
                                        [-12.007, -0.997],
                                        [0.309, -3.719],
                                        [-6.63, 3.735],
                                        [-1.086, 13.071],
                                        [11.861, 0.985],
                                        [-4.405, 5.041]
                                    ],
                                    "v": [
                                        [5.686, 10.933],
                                        [-14.25, -12.613],
                                        [-10.815, -22.679],
                                        [-22.844, -3.939],
                                        [-1.141, 21.694],
                                        [23.93, 3.532]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.847, 0.227, 0.361, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [25.176, 27.975],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 2",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [1.129, -2.133],
                                        [3.979, 2.107],
                                        [-1.129, 2.133],
                                        [-3.978, -2.106]
                                    ],
                                    "o": [
                                        [-1.129, 2.133],
                                        [-3.979, -2.106],
                                        [1.13, -2.134],
                                        [3.979, 2.107]
                                    ],
                                    "v": [
                                        [7.205, 3.813],
                                        [-2.044, 3.863],
                                        [-7.205, -3.814],
                                        [2.044, -3.863]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 0.451, 0.569, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [33.168, 13.027],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 3",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [1.085, -13.072],
                                        [13.072, 1.085],
                                        [-1.085, 13.072],
                                        [-13.071, -1.085]
                                    ],
                                    "o": [
                                        [-1.085, 13.072],
                                        [-13.071, -1.086],
                                        [1.085, -13.072],
                                        [13.072, 1.086]
                                    ],
                                    "v": [
                                        [23.669, 1.965],
                                        [-1.965, 23.669],
                                        [-23.668, -1.965],
                                        [1.964, -23.669]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.937, 0.329, 0.435, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [26, 26],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 4",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 9,
            "ty": 0,
            "nm": "Text",
            "refId": "comp_13",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "a": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "w": 1000,
            "h": 1000,
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 10,
            "ty": 4,
            "nm": "buble Outlines",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [668.994, 615.748, 0]
                },
                "a": {
                    "a": 0,
                    "k": [89.148, 72.386, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [{
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [17.175, -12.465],
                                        [-21.777, 12.221],
                                        [23.58, 3.505]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ind": 1,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [-24.27, 13.208],
                                        [17.415, -13.208],
                                        [24.27, 3.883],
                                        [23.973, 3.939]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 2",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "mm",
                            "mm": 1,
                            "nm": "Merge Paths 1",
                            "mn": "ADBE Vector Filter - Merge"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [24.52, 99.331],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 1",
                    "np": 4,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [16.844, -12.776],
                                        [-23.475, 12.776],
                                        [23.475, 3.755]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [24.972, 99.27],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 2",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [39.501, 0],
                                        [0, -39.501],
                                        [-39.501, 0],
                                        [0, 39.501]
                                    ],
                                    "o": [
                                        [-39.501, 0],
                                        [0, 39.501],
                                        [39.501, 0],
                                        [0, -39.501]
                                    ],
                                    "v": [
                                        [0, -71.637],
                                        [-71.637, 0.001],
                                        [0, 71.637],
                                        [71.637, 0.001]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ind": 1,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [39.776, 0],
                                        [0, 39.776],
                                        [-39.776, 0],
                                        [0, -39.777]
                                    ],
                                    "o": [
                                        [-39.776, 0],
                                        [0, -39.777],
                                        [39.776, 0],
                                        [0, 39.776]
                                    ],
                                    "v": [
                                        [0, 72.137],
                                        [-72.137, 0.001],
                                        [0, -72.137],
                                        [72.137, 0.001]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 2",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "mm",
                            "mm": 1,
                            "nm": "Merge Paths 1",
                            "mn": "ADBE Vector Filter - Merge"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [105.91, 72.387],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 3",
                    "np": 4,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, -39.702],
                                        [-39.702, 0],
                                        [0, 39.702],
                                        [39.701, 0]
                                    ],
                                    "o": [
                                        [0, 39.702],
                                        [39.701, 0],
                                        [0, -39.702],
                                        [-39.702, 0]
                                    ],
                                    "v": [
                                        [-71.886, 0],
                                        [0, 71.887],
                                        [71.886, 0],
                                        [0, -71.887]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [105.91, 72.387],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 4",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 11,
            "ty": 0,
            "nm": "404 text",
            "refId": "comp_14",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "a": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "w": 1000,
            "h": 1000,
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 12,
            "ty": 4,
            "nm": "cat Outlines",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [477.363, 647.652, 0]
                },
                "a": {
                    "a": 0,
                    "k": [114.78, 181.106, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [{
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [1.059, 0],
                                        [0.035, 0.001],
                                        [0, 0],
                                        [-0.054, 1.103],
                                        [-1.106, -0.055],
                                        [0, 0],
                                        [0.056, -1.103]
                                    ],
                                    "o": [
                                        [-0.034, 0],
                                        [0, 0],
                                        [-1.104, -0.054],
                                        [0.055, -1.103],
                                        [0, 0],
                                        [1.102, 0.055],
                                        [-0.053, 1.069]
                                    ],
                                    "v": [
                                        [7.877, 2.418],
                                        [7.774, 2.416],
                                        [-7.975, 1.632],
                                        [-9.873, -0.464],
                                        [-7.777, -2.363],
                                        [7.975, -1.58],
                                        [9.872, 0.517]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [123.409, 15.802],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 19",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [0, 0],
                                        [0, 0],
                                        [-0.988, -0.496],
                                        [0.495, -0.988]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0],
                                        [0.495, -0.988],
                                        [0.987, 0.495],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [-1.125, 6.904],
                                        [-4.701, 5.111],
                                        [0.629, -5.515],
                                        [3.314, -6.408],
                                        [4.206, -3.723]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [121.74, 16.257],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 20",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 2.507],
                                        [14.595, 0],
                                        [0, -2.507],
                                        [-14.596, 0]
                                    ],
                                    "o": [
                                        [0, -2.507],
                                        [-14.596, 0],
                                        [0, 2.507],
                                        [14.595, 0]
                                    ],
                                    "v": [
                                        [26.428, 0],
                                        [0.001, -4.54],
                                        [-26.428, 0],
                                        [0.001, 4.54]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.886, 0.835, 0.843, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [141.394, 340.906],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 1",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.187, 0],
                                        [0.236, 0.583],
                                        [-0.768, 0.312],
                                        [-29.12, 0],
                                        [-8.958, -3.666],
                                        [0.314, -0.766],
                                        [0.772, 0.316],
                                        [28.905, 0],
                                        [8.787, -3.557]
                                    ],
                                    "o": [
                                        [-0.593, 0],
                                        [-0.311, -0.768],
                                        [9.055, -3.665],
                                        [29.292, 0],
                                        [0.767, 0.315],
                                        [-0.313, 0.765],
                                        [-8.686, -3.556],
                                        [-28.737, 0],
                                        [-0.184, 0.074]
                                    ],
                                    "v": [
                                        [-62.046, 5.032],
                                        [-63.437, 4.095],
                                        [-62.609, 2.141],
                                        [-0.03, -5.236],
                                        [62.614, 2.142],
                                        [63.434, 4.099],
                                        [61.477, 4.92],
                                        [-0.03, -2.236],
                                        [-61.484, 4.923]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [125.189, 333.222],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 2",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [19.438, 0],
                                        [13.765, 1.633],
                                        [0, 2.773],
                                        [0, 0],
                                        [-1.104, 0],
                                        [0, -1.104],
                                        [0, 0],
                                        [-41.412, 0],
                                        [-2.842, 2.866],
                                        [0, 0],
                                        [-1.104, 0],
                                        [0, -1.104],
                                        [0, 0],
                                        [20.208, -2.397]
                                    ],
                                    "o": [
                                        [-19.438, 0],
                                        [-20.209, -2.397],
                                        [0, 0],
                                        [0, -1.104],
                                        [1.105, 0],
                                        [0, 0],
                                        [2.843, 2.866],
                                        [41.412, 0],
                                        [0, 0],
                                        [0, -1.104],
                                        [1.105, 0],
                                        [0, 0],
                                        [0, 2.773],
                                        [-13.765, 1.633]
                                    ],
                                    "v": [
                                        [0, 15.757],
                                        [-51.489, 13.226],
                                        [-74.484, 5.16],
                                        [-74.484, -13.757],
                                        [-72.484, -15.757],
                                        [-70.484, -13.757],
                                        [-70.484, 4.86],
                                        [0, 11.757],
                                        [70.484, 4.86],
                                        [70.484, -13.757],
                                        [72.484, -15.757],
                                        [74.484, -13.757],
                                        [74.484, 5.16],
                                        [51.49, 13.226]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [124.437, 165.793],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 3",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [19.418, 0],
                                        [12, 0.959],
                                        [-0.066, 0.825],
                                        [-0.837, -0.064],
                                        [-14.448, 0],
                                        [-1.835, 2.963],
                                        [-0.791, 0],
                                        [0, -0.828],
                                        [22.554, -2.676]
                                    ],
                                    "o": [
                                        [-14.526, 0],
                                        [-0.826, -0.065],
                                        [0.065, -0.826],
                                        [11.922, 0.952],
                                        [42.991, 0],
                                        [0.056, -0.776],
                                        [0.828, 0],
                                        [0, 1.589],
                                        [-13.747, 1.63]
                                    ],
                                    "v": [
                                        [-15.998, 5.799],
                                        [-56.545, 4.333],
                                        [-57.92, 2.719],
                                        [-56.306, 1.343],
                                        [-15.998, 2.799],
                                        [54.99, -4.409],
                                        [56.486, -5.799],
                                        [57.986, -4.299],
                                        [35.432, 3.271]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [140.571, 168.906],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 4",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [14.526, 0],
                                        [13.748, 1.63],
                                        [0, 1.589],
                                        [-0.829, 0],
                                        [-0.056, -0.776],
                                        [-42.991, 0],
                                        [-11.922, 0.952],
                                        [-0.067, -0.826],
                                        [0.826, -0.065]
                                    ],
                                    "o": [
                                        [-19.418, 0],
                                        [-22.553, -2.676],
                                        [0, -0.828],
                                        [0.792, 0],
                                        [1.836, 2.963],
                                        [14.448, 0],
                                        [0.814, -0.066],
                                        [0.066, 0.825],
                                        [-11.999, 0.959]
                                    ],
                                    "v": [
                                        [15.998, 5.799],
                                        [-35.433, 3.271],
                                        [-57.986, -4.299],
                                        [-56.486, -5.799],
                                        [-54.99, -4.409],
                                        [15.998, 2.799],
                                        [56.306, 1.343],
                                        [57.921, 2.719],
                                        [56.545, 4.333]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [108.44, 162.792],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 5",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [19.437, 0],
                                        [13.766, 1.633],
                                        [0, 2.773],
                                        [-1.455, 0.915],
                                        [-0.588, -0.934],
                                        [0.858, -0.611],
                                        [-39.858, 0],
                                        [-4.429, 2.835],
                                        [0.029, 0.016],
                                        [-0.546, 0.959],
                                        [-0.957, -0.548],
                                        [0, -1.362],
                                        [20.208, -2.398]
                                    ],
                                    "o": [
                                        [-19.437, 0],
                                        [-20.209, -2.398],
                                        [0, -1.271],
                                        [0.933, -0.586],
                                        [0.568, 0.903],
                                        [4.413, 2.835],
                                        [39.844, 0],
                                        [-0.028, -0.017],
                                        [-0.959, -0.548],
                                        [0.548, -0.959],
                                        [1.676, 0.957],
                                        [0, 2.773],
                                        [-13.766, 1.633]
                                    ],
                                    "v": [
                                        [0, 7.32],
                                        [-51.489, 4.789],
                                        [-74.484, -3.277],
                                        [-72.292, -6.572],
                                        [-69.535, -5.945],
                                        [-70.066, -3.25],
                                        [0, 3.32],
                                        [70.063, -3.247],
                                        [69.977, -3.297],
                                        [69.23, -6.026],
                                        [71.959, -6.772],
                                        [74.484, -3.277],
                                        [51.49, 4.789]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [124.437, 155.313],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 6",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.341, 0],
                                        [8.168, 10.039],
                                        [3.655, 16.203],
                                        [-5.521, 17.555],
                                        [-8.298, 10.043],
                                        [0.231, 8.958],
                                        [-1.104, 0.028],
                                        [-0.029, -1.103],
                                        [4.651, -5.629],
                                        [2.925, -9.299],
                                        [-4.702, -20.854],
                                        [-15.843, -16.775],
                                        [-0.035, -0.043],
                                        [-35.249, 0.116],
                                        [-5.559, 7.043],
                                        [-0.04, 0.042],
                                        [-3.548, 15.733],
                                        [5.31, 16.885],
                                        [7.967, 9.644],
                                        [-0.267, 10.299],
                                        [-1.091, -0.052],
                                        [0.029, -1.105],
                                        [-4.244, -5.136],
                                        [-3.08, -9.793],
                                        [4.857, -21.542],
                                        [16.427, -17.427],
                                        [28.718, -0.087]
                                    ],
                                    "o": [
                                        [-28.302, 0],
                                        [-16.428, -17.426],
                                        [-4.857, -21.543],
                                        [3.081, -9.795],
                                        [4.242, -5.134],
                                        [-0.028, -1.105],
                                        [1.077, -0.066],
                                        [0.265, 10.301],
                                        [-7.968, 9.643],
                                        [-5.31, 16.884],
                                        [3.549, 15.733],
                                        [0.037, 0.04],
                                        [5.801, 7.258],
                                        [33.924, -0.103],
                                        [0.037, -0.046],
                                        [15.844, -16.776],
                                        [4.702, -20.853],
                                        [-2.924, -9.298],
                                        [-4.652, -5.63],
                                        [0.027, -1.104],
                                        [1.105, 0.028],
                                        [-0.232, 8.957],
                                        [8.297, 10.043],
                                        [5.522, 17.556],
                                        [-3.654, 16.201],
                                        [-7.949, 9.882],
                                        [-0.339, 0.001]
                                    ],
                                    "v": [
                                        [0.339, 91.952],
                                        [-71.485, 78.371],
                                        [-99.372, 21.779],
                                        [-98.357, -37.985],
                                        [-81.209, -67.881],
                                        [-71.585, -89.835],
                                        [-69.637, -91.886],
                                        [-67.586, -89.939],
                                        [-78.125, -65.333],
                                        [-94.541, -36.786],
                                        [-95.47, 20.898],
                                        [-68.523, 75.681],
                                        [-68.415, 75.806],
                                        [1.349, 87.952],
                                        [68.408, 75.814],
                                        [68.523, 75.681],
                                        [95.469, 20.897],
                                        [94.542, -36.786],
                                        [78.127, -65.333],
                                        [67.588, -89.939],
                                        [69.638, -91.886],
                                        [71.586, -89.835],
                                        [81.211, -67.88],
                                        [98.357, -37.985],
                                        [99.372, 21.779],
                                        [71.49, 78.366],
                                        [1.361, 91.952]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [125.08, 264.504],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 7",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.234, 0],
                                        [0.303, 0.802],
                                        [1.629, 2.507],
                                        [-0.926, 0.602],
                                        [-0.601, -0.926],
                                        [-1.209, -3.207],
                                        [1.033, -0.39]
                                    ],
                                    "o": [
                                        [-0.809, 0],
                                        [-1.107, -2.938],
                                        [-0.603, -0.927],
                                        [0.928, -0.602],
                                        [1.787, 2.749],
                                        [0.389, 1.033],
                                        [-0.232, 0.087]
                                    ],
                                    "v": [
                                        [2.168, 6.435],
                                        [0.296, 5.139],
                                        [-3.826, -3.066],
                                        [-3.239, -5.833],
                                        [-0.473, -5.246],
                                        [4.04, 3.729],
                                        [2.873, 6.306]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [207.333, 222.298],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 8",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [1.02, 0],
                                        [0.064, 0.006],
                                        [-0.105, 1.099],
                                        [2.763, 9.485],
                                        [-1.061, 0.309],
                                        [-0.308, -1.062],
                                        [1.248, -13.044]
                                    ],
                                    "o": [
                                        [-0.063, 0],
                                        [-1.1, -0.105],
                                        [1.2, -12.532],
                                        [-0.31, -1.061],
                                        [1.058, -0.31],
                                        [2.909, 9.981],
                                        [-0.099, 1.036]
                                    ],
                                    "v": [
                                        [0.695, 18.849],
                                        [0.503, 18.841],
                                        [-1.298, 16.66],
                                        [-3.622, -16.059],
                                        [-2.262, -18.539],
                                        [0.217, -17.178],
                                        [2.684, 17.039]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [214.211, 251.048],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 9",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.632, 0],
                                        [0.349, 0.243],
                                        [-0.632, 0.907],
                                        [-2.733, 17.109],
                                        [-1.087, -0.178],
                                        [0.174, -1.09],
                                        [9.283, -13.317]
                                    ],
                                    "o": [
                                        [-0.395, 0],
                                        [-0.906, -0.632],
                                        [8.955, -12.845],
                                        [0.175, -1.091],
                                        [1.091, 0.174],
                                        [-2.824, 17.676],
                                        [-0.389, 0.557]
                                    ],
                                    "v": [
                                        [-9.44, 26.41],
                                        [-10.582, 26.051],
                                        [-11.079, 23.266],
                                        [7.587, -24.572],
                                        [9.877, -26.232],
                                        [11.537, -23.942],
                                        [-7.797, 25.554]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [204.509, 300.961],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 10",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.001, -0.015],
                                        [-1.543, -0.123],
                                        [-0.005, 0.112],
                                        [0.363, 2.901],
                                        [1.283, 0.065],
                                        [0.076, -1.49],
                                        [0.721, -2.846]
                                    ],
                                    "o": [
                                        [1.536, 0.231],
                                        [0.017, -0.108],
                                        [0.655, -2.849],
                                        [0.076, -1.489],
                                        [-1.283, -0.065],
                                        [0.43, 2.904],
                                        [0, 0.014]
                                    ],
                                    "v": [
                                        [-2.731, 5.402],
                                        [1.888, 5.921],
                                        [1.933, 5.595],
                                        [2.37, -3.03],
                                        [0.174, -5.856],
                                        [-2.296, -3.266],
                                        [-2.733, 5.359]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [129.337, 245.459],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 12",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.721, -2.846],
                                        [-0.06, -0.265],
                                        [-0.262, -0.011],
                                        [-0.207, 0.003],
                                        [-0.107, 0.007],
                                        [-0.954, 0.08],
                                        [-0.005, 0.105],
                                        [0.363, 2.9],
                                        [1.283, 0.065],
                                        [0.075, -1.489]
                                    ],
                                    "o": [
                                        [-0.015, 0.289],
                                        [0.263, 0.012],
                                        [0.207, -0.003],
                                        [0.071, -0.051],
                                        [0.956, -0.059],
                                        [0.018, -0.1],
                                        [0.654, -2.849],
                                        [0.075, -1.49],
                                        [-1.283, -0.065],
                                        [0.429, 2.904]
                                    ],
                                    "v": [
                                        [-2.725, 5.212],
                                        [-2.644, 6.042],
                                        [-1.856, 6.068],
                                        [-1.236, 6.061],
                                        [-0.975, 5.964],
                                        [1.89, 5.748],
                                        [1.941, 5.448],
                                        [2.378, -3.176],
                                        [0.182, -6.003],
                                        [-2.288, -3.413]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [137.069, 245.543],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 13",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [-0.005, -0.102],
                                        [-1.576, 0.164],
                                        [0.345, 2.76],
                                        [1.283, 0.065],
                                        [0.075, -1.49],
                                        [0.721, -2.846]
                                    ],
                                    "o": [
                                        [1.576, -0.158],
                                        [0.576, -2.714],
                                        [0.075, -1.489],
                                        [-1.283, -0.065],
                                        [0.429, 2.904],
                                        [-0.005, 0.105]
                                    ],
                                    "v": [
                                        [-2.707, 5.793],
                                        [2.021, 5.307],
                                        [2.382, -2.902],
                                        [0.185, -5.728],
                                        [-2.285, -3.138],
                                        [-2.721, 5.487]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [144.805, 245.206],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 14",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [-3.333, 4.083],
                                        [5.073, 4.939],
                                        [-0.017, -15.221]
                                    ],
                                    "o": [
                                        [-9.917, -3.583],
                                        [0, 0],
                                        [6.833, 0]
                                    ],
                                    "v": [
                                        [12.691, 1.927],
                                        [-7.559, -9.841],
                                        [-12.674, 9.841]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 1, 1, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [82.934, 246.518],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 15",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [3.165, 9.274],
                                        [3.502, -10.393]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 1, 1, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [193.419, 163.94],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 16",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [7.408, 9.215],
                                        [8.002, -10.697]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 1, 1, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [188.169, 165.717],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 17",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [-5.981, -6.57],
                                        [2.46, -2.239],
                                        [5.981, 6.569],
                                        [-2.46, 2.24]
                                    ],
                                    "o": [
                                        [5.981, 6.569],
                                        [-2.46, 2.24],
                                        [-5.982, -6.57],
                                        [2.46, -2.239]
                                    ],
                                    "v": [
                                        [4.454, -4.056],
                                        [10.83, 11.895],
                                        [-4.454, 4.056],
                                        [-10.83, -11.896]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 0.82, 0.643, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [173.694, 37.626],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 18",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.124, 0],
                                        [0.338, 0.236],
                                        [0.089, 0.53],
                                        [-3.175, 11.315],
                                        [-0.435, 17.264],
                                        [-1.081, 0],
                                        [-0.017, -0.001],
                                        [0.028, -1.105],
                                        [-4.906, -18.458],
                                        [0.1, -0.349],
                                        [-1.241, -9.849],
                                        [-0.118, 0.07],
                                        [-0.572, -0.943],
                                        [0.942, -0.574],
                                        [31.742, -5.998]
                                    ],
                                    "o": [
                                        [-0.406, 0],
                                        [-0.441, -0.308],
                                        [-1.737, -10.416],
                                        [-4.907, -18.659],
                                        [0.028, -1.087],
                                        [0.017, 0],
                                        [1.105, 0.027],
                                        [-0.43, 17.058],
                                        [0.094, 0.35],
                                        [-2.94, 10.155],
                                        [29.387, -5.766],
                                        [0.943, -0.571],
                                        [0.572, 0.944],
                                        [-0.511, 0.311],
                                        [-0.124, 0.023]
                                    ],
                                    "v": [
                                        [-19.659, 47.746],
                                        [-20.804, 47.386],
                                        [-21.632, 46.075],
                                        [-19.224, 9.875],
                                        [-26.157, -45.797],
                                        [-24.158, -47.746],
                                        [-24.107, -47.745],
                                        [-22.158, -45.695],
                                        [-15.217, 9.381],
                                        [-15.228, 10.45],
                                        [-17.982, 43.387],
                                        [23.275, 30.599],
                                        [26.02, 31.272],
                                        [25.351, 34.017],
                                        [-19.287, 47.711]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [81.191, 220.363],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 21",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.459, 0],
                                        [0.105, 0.017],
                                        [0.584, 0.253],
                                        [-0.439, 1.014],
                                        [-1.018, -0.437],
                                        [-21.048, -3.605],
                                        [5.005, 8.251],
                                        [-0.944, 0.573],
                                        [-0.572, -0.944],
                                        [0.593, -15.448],
                                        [0.442, -0.363]
                                    ],
                                    "o": [
                                        [-0.105, 0],
                                        [-22.902, -3.664],
                                        [-1.013, -0.438],
                                        [0.437, -1.013],
                                        [0.133, 0.059],
                                        [0.232, -13.669],
                                        [-0.573, -0.944],
                                        [0.944, -0.571],
                                        [5.687, 9.376],
                                        [-0.021, 0.572],
                                        [-0.361, 0.295]
                                    ],
                                    "v": [
                                        [18.334, 20.557],
                                        [18.018, 20.532],
                                        [-19.445, 10.634],
                                        [-20.485, 8.005],
                                        [-17.856, 6.963],
                                        [16.398, 16.209],
                                        [9.124, -17.239],
                                        [9.797, -19.986],
                                        [12.544, -19.313],
                                        [20.332, 18.633],
                                        [19.603, 20.104]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [172.677, 242.033],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 22",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [12.042, 0],
                                        [3.653, 0.525],
                                        [2.955, 0.779],
                                        [-0.281, 1.069],
                                        [-1.067, -0.28],
                                        [-2.983, -0.428],
                                        [-17.332, 12.551],
                                        [-1.334, 25.266],
                                        [-1.086, -0.061],
                                        [0.059, -1.104],
                                        [18.373, -13.302]
                                    ],
                                    "o": [
                                        [-5.229, 0],
                                        [-3.134, -0.45],
                                        [-1.068, -0.281],
                                        [0.282, -1.068],
                                        [2.806, 0.739],
                                        [11.467, 1.647],
                                        [16.126, -11.675],
                                        [0.059, -1.104],
                                        [1.102, 0.058],
                                        [-1.282, 24.297],
                                        [-12.88, 9.327]
                                    ],
                                    "v": [
                                        [-21.66, 42.283],
                                        [-35.196, 41.309],
                                        [-44.372, 39.458],
                                        [-45.796, 37.014],
                                        [-43.352, 35.591],
                                        [-34.627, 37.35],
                                        [16, 27.334],
                                        [42.024, -40.329],
                                        [44.127, -42.221],
                                        [46.018, -40.118],
                                        [18.345, 30.574]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [149.364, 215.148],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 23",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0]
                                    ],
                                    "o": [
                                        [0, 0]
                                    ],
                                    "v": [
                                        [160.883, 136.946]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 24",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, -35.201],
                                        [22.159, 0],
                                        [0, 35.201],
                                        [-22.159, 0]
                                    ],
                                    "o": [
                                        [0, 35.201],
                                        [-22.159, 0],
                                        [0, -35.201],
                                        [22.159, 0]
                                    ],
                                    "v": [
                                        [40.123, 0.535],
                                        [0.001, 47.125],
                                        [-40.123, 0.535],
                                        [0.001, -47.125]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 1, 1, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [150.742, 92.368],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 25",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0.912, 0],
                                        [0.15, 0.034],
                                        [-0.245, 1.077],
                                        [-0.97, 3.914],
                                        [-1.149, 11.141],
                                        [5.037, 14.587],
                                        [16.592, 8.183],
                                        [23.002, -6.755],
                                        [7.14, -4.932],
                                        [-7.269, -29.781],
                                        [-2.979, -8.148],
                                        [-2.113, -9.481],
                                        [1.078, -0.24],
                                        [0.24, 1.077],
                                        [3.231, 8.839],
                                        [2.122, 8.691],
                                        [-27.219, 18.798],
                                        [-9.373, 2.752],
                                        [-21.23, -10.472],
                                        [-5.833, -16.89],
                                        [1.517, -14.717],
                                        [2.673, -10.779],
                                        [0.909, -3.982]
                                    ],
                                    "o": [
                                        [-0.147, 0],
                                        [-1.077, -0.246],
                                        [0.918, -4.019],
                                        [2.64, -10.644],
                                        [2.35, -22.791],
                                        [-5.492, -15.901],
                                        [-20.352, -10.04],
                                        [-8.963, 2.631],
                                        [-25.842, 17.848],
                                        [2.069, 8.477],
                                        [3.277, 8.961],
                                        [0.24, 1.078],
                                        [-1.079, 0.235],
                                        [-2.056, -9.223],
                                        [-3.018, -8.252],
                                        [-7.665, -31.408],
                                        [7.491, -5.174],
                                        [23.939, -7.031],
                                        [17.528, 8.645],
                                        [6.876, 19.913],
                                        [-1.178, 11.421],
                                        [-0.966, 3.897],
                                        [-0.212, 0.927]
                                    ],
                                    "v": [
                                        [76.342, 77.992],
                                        [75.894, 77.942],
                                        [74.389, 75.547],
                                        [77.281, 63.567],
                                        [83.8, 30.774],
                                        [79.863, -24.012],
                                        [45.113, -61.911],
                                        [-24.126, -67.158],
                                        [-48.395, -55.76],
                                        [-78.968, 22.644],
                                        [-70.839, 47.696],
                                        [-62.063, 75.404],
                                        [-63.58, 77.791],
                                        [-65.967, 76.273],
                                        [-74.596, 49.07],
                                        [-82.855, 23.594],
                                        [-50.667, -59.051],
                                        [-25.253, -70.996],
                                        [46.883, -65.497],
                                        [83.643, -25.317],
                                        [87.779, 31.184],
                                        [81.163, 64.53],
                                        [78.29, 76.437]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [118.108, 78.277],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 26",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [0, 0],
                                        [-8.451, -0.198],
                                        [-11.707, 0.072],
                                        [0, 0],
                                        [2.579, 3.033],
                                        [-0.681, 3.893],
                                        [43.608, 6.956],
                                        [-103.854, 30.605],
                                        [2.79, 0.221],
                                        [15.207, -14.014],
                                        [3.695, -14.744],
                                        [-0.357, -10.362]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [11.698, 0.276],
                                        [12.004, -0.074],
                                        [0, 0],
                                        [0.075, -1.374],
                                        [-22.019, 5.951],
                                        [0, 0],
                                        [-7.039, -0.159],
                                        [-20.616, -1.629],
                                        [-11.178, 10.302],
                                        [-3.662, 14.612],
                                        [0.008, 6.737]
                                    ],
                                    "v": [
                                        [-55.807, 80.745],
                                        [2.549, 87.245],
                                        [36.67, 86.579],
                                        [88.951, 80.745],
                                        [86.845, 61.379],
                                        [87.221, 58.047],
                                        [-28.341, 59.568],
                                        [14.43, -85.448],
                                        [-1.599, -85.893],
                                        [-51.844, -60.608],
                                        [-72.195, -16.475],
                                        [-55.688, 59.288]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [107.633, 92.468],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 27",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [7.955, 0.192],
                                        [10.852, 8.496],
                                        [0.004, -0.002],
                                        [3.948, 1.729],
                                        [2.033, 3.112],
                                        [0, 0],
                                        [-38.687, 7.088],
                                        [0.631, 2.749],
                                        [0.145, 5.559],
                                        [0.018, 5.405],
                                        [4.396, 2.468],
                                        [3.098, -0.615],
                                        [0, -1.768],
                                        [-6.729, -43.832],
                                        [-0.907, -0.841],
                                        [-13.176, 5.284],
                                        [-6.343, -1.262],
                                        [-10.497, 6.188]
                                    ],
                                    "o": [
                                        [-13.329, -0.322],
                                        [-0.004, 0.002],
                                        [-3.921, 1.532],
                                        [-3.46, -1.516],
                                        [-4.782, -7.321],
                                        [31.44, 0.906],
                                        [0, 0],
                                        [-33.439, 6.556],
                                        [-0.141, -5.405],
                                        [-0.015, -4.334],
                                        [-2.706, -1.519],
                                        [1.074, 4.071],
                                        [0, 9.709],
                                        [-1.775, 7.415],
                                        [1, 0.927],
                                        [6.532, 2.031],
                                        [9.302, 1.85],
                                        [-7.631, 1.522]
                                    ],
                                    "v": [
                                        [18.227, 44.58],
                                        [-21.14, 34.102],
                                        [-21.152, 34.109],
                                        [-33.257, 34.421],
                                        [-41.389, 26.916],
                                        [-47.43, -21.176],
                                        [70.611, -26.832],
                                        [70.654, -29.695],
                                        [-57.739, -30.675],
                                        [-57.683, -46.852],
                                        [-61.99, -60.949],
                                        [-71.286, -61.533],
                                        [-69.54, -52.532],
                                        [-58.208, 22.76],
                                        [-59.96, 61.541],
                                        [-15.99, 48.104],
                                        [5.124, 50.917],
                                        [41.609, 42.569]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [121.493, 204.567],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 28",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [-1.776, 7.415],
                                        [0, 9.708],
                                        [-3.696, 14.744],
                                        [-11.177, 10.301],
                                        [-20.615, -1.629],
                                        [-6.826, -3.206],
                                        [-13.416, -38.068],
                                        [0.791, -19.079],
                                        [5.134, -9.31],
                                        [3.177, -16.229],
                                        [8.415, -9.192],
                                        [11.742, 2.336],
                                        [6.532, 2.031],
                                        [0.999, 0.927]
                                    ],
                                    "o": [
                                        [-6.73, -43.832],
                                        [0, -9.709],
                                        [3.695, -14.744],
                                        [15.207, -14.015],
                                        [7.518, 0.594],
                                        [15.722, 7.383],
                                        [12.286, 42.605],
                                        [1.796, 10.828],
                                        [-5.091, 9.233],
                                        [-4.683, 10.452],
                                        [-7.966, 8.937],
                                        [-6.344, -1.261],
                                        [-13.176, 5.284],
                                        [-0.908, -0.84]
                                    ],
                                    "v": [
                                        [-59.228, 91.336],
                                        [-70.56, 16.045],
                                        [-87.075, -59.998],
                                        [-66.725, -104.13],
                                        [-16.48, -129.415],
                                        [30.099, -126.148],
                                        [78.485, -77.687],
                                        [71.933, 18.278],
                                        [70.208, 45.438],
                                        [64.247, 80.767],
                                        [47.332, 105.893],
                                        [4.104, 119.493],
                                        [-17.011, 116.68],
                                        [-60.981, 130.117]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 0.741, 0.431, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [122.513, 135.991],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 29",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [5.904, 9.734],
                                        [0, 0],
                                        [-23.958, -5.486]
                                    ],
                                    "o": [
                                        [0, 0],
                                        [0, 0],
                                        [0.534, -13.935]
                                    ],
                                    "v": [
                                        [8.441, -17.963],
                                        [-17.492, 8.286],
                                        [16.958, 17.964]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [174.052, 242.627],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 30",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [1.768, 1.115],
                                        [7.211, -0.755],
                                        [12.955, -0.025],
                                        [13.357, 0.953],
                                        [10.431, 3.088],
                                        [2.581, 3.131],
                                        [1.89, 2.681],
                                        [0, -5.401],
                                        [-55.064, 0],
                                        [0, 8.262]
                                    ],
                                    "o": [
                                        [-5.497, 4.382],
                                        [-12.814, 1.341],
                                        [-13.391, 0.025],
                                        [-11.009, -0.786],
                                        [-4.198, -1.242],
                                        [-2.136, -2.591],
                                        [-28.599, 2.63],
                                        [0, 8.262],
                                        [55.064, 0],
                                        [0, -1.196]
                                    ],
                                    "v": [
                                        [96.992, -5.132],
                                        [76, 2.402],
                                        [37.471, 5.59],
                                        [-2.713, 4.913],
                                        [-35.776, -0.202],
                                        [-47.004, -6.075],
                                        [-52.021, -14.421],
                                        [-99.702, -1.657],
                                        [0, 14.421],
                                        [99.702, -1.657]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.898, 0.855, 0.867, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [99.952, 347.541],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 31",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 13,
            "ty": 4,
            "nm": "leg L Outlines",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 1,
                    "k": [{
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 0,
                            "s": [0],
                            "e": [8.6]
                        }, {
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 20,
                            "s": [8.6],
                            "e": [0]
                        }, {
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 40,
                            "s": [0],
                            "e": [8.6]
                        }, {
                            "i": {
                                "x": [0.25],
                                "y": [1]
                            },
                            "o": {
                                "x": [0.75],
                                "y": [0]
                            },
                            "n": ["0p25_1_0p75_0"],
                            "t": 60,
                            "s": [8.6],
                            "e": [0]
                        }, {
                            "t": 81
                        }]
                },
                "p": {
                    "a": 0,
                    "k": [527.429, 521.931, 0]
                },
                "a": {
                    "a": 0,
                    "k": [64.8, 71.558, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "shapes": [{
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [16.119, -0.001],
                                        [5.433, 2.212],
                                        [-0.416, 1.024],
                                        [-1.025, -0.419],
                                        [-10.243, 14.019],
                                        [12.392, 14.891],
                                        [0.744, 2.049],
                                        [-0.951, 1.378],
                                        [-1.649, 0.024],
                                        [-0.028, 0],
                                        [-2.584, -2.358],
                                        [-2.114, -2.936],
                                        [-1.162, -1.778],
                                        [-7.575, -2.394],
                                        [-3.588, 3.203],
                                        [0.627, 3.205],
                                        [5.491, 2.874],
                                        [8.261, 8.591],
                                        [2.874, 2.783],
                                        [6.312, -4.018],
                                        [0.252, -3.77],
                                        [0, 0],
                                        [0.048, -0.35],
                                        [1.833, -1.285],
                                        [4.666, 0.998],
                                        [1.085, 0.132],
                                        [6.744, -6.995],
                                        [2.367, -10.562],
                                        [1.077, 0.242],
                                        [-0.241, 1.078],
                                        [-6.636, 6.884],
                                        [-11.889, -1.44],
                                        [-1.491, -0.319],
                                        [-2.22, 1.557],
                                        [-0.22, 1.609],
                                        [-0.019, 0.269],
                                        [0, 0],
                                        [-4.669, 2.973],
                                        [-10.537, -10.205],
                                        [-2.74, -2.85],
                                        [-10.708, -5.603],
                                        [-1.287, -6.585],
                                        [4.022, -3.591],
                                        [5.176, 1.635],
                                        [4.782, 7.316],
                                        [1.18, 1.639],
                                        [1.78, 1.625],
                                        [1.94, -0.061],
                                        [0.05, -0.074],
                                        [-0.203, -0.559],
                                        [-1.095, -1.152],
                                        [-0.029, -0.034],
                                        [12.073, -16.522]
                                    ],
                                    "o": [
                                        [-5.354, 0.001],
                                        [-1.024, -0.416],
                                        [0.417, -1.023],
                                        [19.891, 8.097],
                                        [10.907, -14.927],
                                        [-1.46, -1.538],
                                        [-0.625, -1.721],
                                        [0.876, -1.271],
                                        [0.029, 0],
                                        [3.196, 0],
                                        [1.99, 1.816],
                                        [1.232, 1.712],
                                        [4.397, 6.727],
                                        [3.814, 1.205],
                                        [2.942, -2.627],
                                        [-0.963, -4.927],
                                        [-11.286, -5.905],
                                        [-2.716, -2.824],
                                        [-5.169, -5.005],
                                        [-2.962, 1.886],
                                        [0, 0],
                                        [-0.026, 0.352],
                                        [-0.375, 2.737],
                                        [-3.647, 2.559],
                                        [-1.4, -0.3],
                                        [-10.642, -1.288],
                                        [-6.12, 6.349],
                                        [-0.241, 1.078],
                                        [-1.078, -0.242],
                                        [2.531, -11.288],
                                        [7.61, -7.893],
                                        [1.265, 0.153],
                                        [3.674, 0.787],
                                        [0.898, -0.628],
                                        [0.036, -0.265],
                                        [0, 0],
                                        [0.284, -4.246],
                                        [6.488, -4.132],
                                        [2.925, 2.832],
                                        [8.409, 8.744],
                                        [6.377, 3.337],
                                        [0.904, 4.625],
                                        [-4.66, 4.161],
                                        [-8.936, -2.824],
                                        [-1.189, -1.82],
                                        [-1.948, -2.707],
                                        [-1.368, -1.249],
                                        [-0.494, 0.007],
                                        [-0.173, 0.25],
                                        [0.526, 1.449],
                                        [0.03, 0.031],
                                        [13.683, 16.402],
                                        [-8.377, 11.465]
                                    ],
                                    "v": [
                                        [-19.024, 60.057],
                                        [-35.298, 56.859],
                                        [-36.397, 54.252],
                                        [-33.791, 53.154],
                                        [17.125, 37.276],
                                        [14.75, -10.337],
                                        [11.101, -15.631],
                                        [11.621, -20.58],
                                        [15.538, -22.588],
                                        [15.623, -22.588],
                                        [24.423, -17.719],
                                        [30.437, -10.754],
                                        [34.033, -5.426],
                                        [50.553, 10.652],
                                        [63.125, 7.259],
                                        [66.768, -1.918],
                                        [55.098, -13.058],
                                        [27.008, -36.436],
                                        [18.61, -44.963],
                                        [-2.486, -53.745],
                                        [-5.987, -43.815],
                                        [-6.049, -42.905],
                                        [-6.158, -41.85],
                                        [-9.485, -35.79],
                                        [-23.086, -35.363],
                                        [-26.893, -36.078],
                                        [-54.661, -26.968],
                                        [-67.453, -1.483],
                                        [-69.842, 0.031],
                                        [-71.357, -2.358],
                                        [-57.541, -29.744],
                                        [-26.412, -40.049],
                                        [-22.248, -39.275],
                                        [-11.782, -39.065],
                                        [-10.121, -42.391],
                                        [-10.04, -43.194],
                                        [-9.978, -44.083],
                                        [-4.634, -57.119],
                                        [21.393, -47.836],
                                        [29.891, -39.208],
                                        [56.952, -16.602],
                                        [70.694, -2.685],
                                        [65.789, 10.243],
                                        [49.348, 14.466],
                                        [30.684, -3.238],
                                        [27.191, -8.416],
                                        [21.726, -14.765],
                                        [15.595, -18.588],
                                        [14.914, -18.308],
                                        [14.861, -16.996],
                                        [17.695, -13.044],
                                        [17.782, -12.947],
                                        [20.355, 39.635]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.322, 0.22, 0.357, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [86.706, 61.501],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 1",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [9.837, 1.222],
                                        [3.84, 3.915],
                                        [1.667, -1.07],
                                        [-7.148, -2.258],
                                        [-3.856, 4.392],
                                        [0.684, 3.606],
                                        [0.001, 0.002]
                                    ],
                                    "o": [
                                        [-4.038, -0.078],
                                        [-1.488, 1.261],
                                        [4.055, 6.046],
                                        [5.299, 1.674],
                                        [2.319, -2.688],
                                        [-0.541, -2.848],
                                        [0, 0]
                                    ],
                                    "v": [
                                        [-1.063, -0.424],
                                        [-12.862, -7.429],
                                        [-17.589, -3.924],
                                        [-1.857, 10.145],
                                        [13.988, 4.986],
                                        [16.905, -4.695],
                                        [12.534, -11.818]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.918, 0.804, 0.71, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [138.514, 63.915],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 2",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [5.299, 1.674],
                                        [4.054, 6.046],
                                        [-1.488, 1.261],
                                        [8.661, -7.611],
                                        [-0.102, -0.094],
                                        [-1.709, -2.385],
                                        [-10.413, -3.24],
                                        [-3.887, 4.506]
                                    ],
                                    "o": [
                                        [-7.148, -2.258],
                                        [1.666, -1.07],
                                        [-9.141, -9.318],
                                        [0.109, 0.099],
                                        [2.17, 1.981],
                                        [5.883, 8.213],
                                        [5.357, 1.668],
                                        [-3.856, 4.392]
                                    ],
                                    "v": [
                                        [10.818, 19.729],
                                        [-4.913, 5.66],
                                        [-0.186, 2.156],
                                        [-26.663, -14.012],
                                        [-14.062, -6.895],
                                        [-10.501, -2.208],
                                        [10.616, 19.956],
                                        [26.663, 14.57]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.827, 0.494, 0.192, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [125.839, 54.33],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 3",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [26.266, 10.578],
                                        [-12.673, 32.219],
                                        [-23.445, -12.366],
                                        [9.841, 32.884]
                                    ],
                                    "o": [
                                        [-19.753, -8.038],
                                        [-19.909, 30.699],
                                        [32.012, 12.893],
                                        [-4.606, 25.437]
                                    ],
                                    "v": [
                                        [-12.826, 15.153],
                                        [-38.686, -43.649],
                                        [-11.463, 30.756],
                                        [48.754, -20.735]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.827, 0.494, 0.192, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [62.742, 86.743],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 4",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [-4.038, -0.078],
                                        [0, 0],
                                        [2.718, 1.422],
                                        [1.849, 1.227],
                                        [4.435, -3.758]
                                    ],
                                    "o": [
                                        [9.837, 1.222],
                                        [-2.488, -2.256],
                                        [-2.016, -1.055],
                                        [-3.178, 4.952],
                                        [3.84, 3.915]
                                    ],
                                    "v": [
                                        [-0.898, 9.518],
                                        [12.697, -1.876],
                                        [4.382, -7.301],
                                        [-1.4, -10.74],
                                        [-12.697, 2.512]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [1, 1, 1, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [138.35, 53.973],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 5",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }, {
                    "ty": "gr",
                    "it": [{
                            "ind": 0,
                            "ty": "sh",
                            "ks": {
                                "a": 0,
                                "k": {
                                    "i": [
                                        [5.064, 2.65],
                                        [11.642, 11.275],
                                        [7.719, -4.915],
                                        [0.304, -4.2],
                                        [6.629, 0.931],
                                        [3.366, -0.445],
                                        [2.84, -1.481],
                                        [-36.519, -19.387],
                                        [31.168, 37.358],
                                        [0.644, 1.772],
                                        [-2.398, 0.035],
                                        [-1.844, -1.684],
                                        [-1.713, -2.379],
                                        [-10.378, -3.279],
                                        [1.877, 9.598]
                                    ],
                                    "o": [
                                        [-14.644, -7.662],
                                        [-5.282, -5.114],
                                        [-4.102, 2.612],
                                        [-0.804, 11.122],
                                        [-3.36, -0.472],
                                        [-3.157, 0.418],
                                        [-41.048, 28.443],
                                        [37.095, 15.098],
                                        [-1.28, -1.348],
                                        [-0.717, -1.977],
                                        [2.662, -0.038],
                                        [2.17, 1.981],
                                        [5.901, 8.194],
                                        [8.525, 2.694],
                                        [-1.195, -6.113]
                                    ],
                                    "v": [
                                        [63.949, -19.709],
                                        [27.926, -51.278],
                                        [4.363, -60.31],
                                        [-0.121, -47.928],
                                        [-18.11, -42.963],
                                        [-28.261, -43.038],
                                        [-37.484, -40.344],
                                        [-26.62, 50.127],
                                        [24.169, -16.545],
                                        [20.905, -21.191],
                                        [23.491, -25.467],
                                        [30.998, -21.12],
                                        [36.738, -14.463],
                                        [57.875, 7.679],
                                        [76.655, -7.18]
                                    ],
                                    "c": true
                                }
                            },
                            "nm": "Path 1",
                            "mn": "ADBE Vector Shape - Group"
                        }, {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.929, 0.592, 0.263, 1]
                            },
                            "o": {
                                "a": 0,
                                "k": 100
                            },
                            "r": 1,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill"
                        }, {
                            "ty": "tr",
                            "p": {
                                "a": 0,
                                "k": [78.782, 66.38],
                                "ix": 2
                            },
                            "a": {
                                "a": 0,
                                "k": [0, 0],
                                "ix": 1
                            },
                            "s": {
                                "a": 0,
                                "k": [100, 100],
                                "ix": 3
                            },
                            "r": {
                                "a": 0,
                                "k": 0,
                                "ix": 6
                            },
                            "o": {
                                "a": 0,
                                "k": 100,
                                "ix": 7
                            },
                            "sk": {
                                "a": 0,
                                "k": 0,
                                "ix": 4
                            },
                            "sa": {
                                "a": 0,
                                "k": 0,
                                "ix": 5
                            },
                            "nm": "Transform"
                        }],
                    "nm": "Group 6",
                    "np": 2,
                    "mn": "ADBE Vector Group"
                }],
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }, {
            "ddd": 0,
            "ind": 14,
            "ty": 0,
            "nm": "Tail",
            "refId": "comp_15",
            "ks": {
                "o": {
                    "a": 0,
                    "k": 100
                },
                "r": {
                    "a": 0,
                    "k": 0
                },
                "p": {
                    "a": 0,
                    "k": [652.5, 522, 0]
                },
                "a": {
                    "a": 0,
                    "k": [500, 500, 0]
                },
                "s": {
                    "a": 0,
                    "k": [100, 100, 100]
                }
            },
            "ao": 0,
            "w": 1000,
            "h": 1000,
            "ip": 0,
            "op": 81,
            "st": 0,
            "bm": 0,
            "sr": 1
        }]
};
Lottie.loadAnimation({
    autoplay: true,
    loop: true,
    container: document.getElementById('404'),
    animationData: data,
    renderer: 'svg'
});
